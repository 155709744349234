<template>
  <v-app class="lato-font">
    <div class="row marg-0 padd-t-128">
      <div class="col-md-7 col-sm-12 col-12 padd-0 h-247">
        <div class="row marg-0 align-center">
          <div class="marg-0">
            <div>
              <span class="fsize24 marg-0 clr-000000"> Confirm OTP</span>
            </div>
            <span class="fsize16-afafaf padd-t-6">Sent to +91 9884454321</span
            ><span class="margin-l-12 fsize16">(Change)</span>
              <form @submit.prevent="mobileNOotp">
            <div class="w-100 h-64 marg-t-18">
              <div class="l-height-16 marg-b-4">
                <span class="fsize14 l-height padd-l-8 clr-55585a"
                  >Mobile OTP</span
                >
              </div>
              <input
                type="text"
                class="w-336 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                placeholder=""
                autocomplete="off"
                v-model="user.mblotp"
                id="mblotp"
                value="mblotp"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :class="{
                  'is-invalid': submitted && $v.user.mblotp.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.mblotp.$error"
                class="invalid-feedback marg-0"
              >
                <span v-if="!$v.user.mblotp.required" class="validatemessage"
                  >Please enter your Mobile OTP</span
                >
              </div>
            </div>
              </form>
            <div class="marg-t-14 fsize12 clr9b9b">
              Resend OTP in 45 seconds
            </div>
          </div>
        </div>
        <div>
          <button
            class="btn-color fsize18 padd-0-16 bor-radius h-40 marg-t-38 w-100per"
            @click="mobileNOotp()"
          >
            Continue
          </button>
        </div>
      </div>
      <div class="col-md-5 col-sm-12 padd-0 hideimgmob">
        <img class="desktopimg" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        mblotp: this.mblotp,
      },
      mblotp: null,
      submitted: true,
    };
  },
  validations: {
    user: {
      mblotp: { required },
    },
  },
  methods: {
    mobileNOotp() {
      this.submitted = true;
      //Error catch user id is invalid  //
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }                    
      this.$router.push("/mobilenumber");
    },
  },
};
</script>

<style>
/* .h-336{
  height: 336px !important;
} */
</style>